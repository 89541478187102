import React, { useCallback, useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import { models, Report } from "powerbi-client";
import { getPowerBIEmbedMeta } from "../../store/api/api";
import { PowerBIEmbedMeta } from "../../types/types";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select, { createFilter } from "react-select";
import { Config } from "react-select/src/filters";
import { PowerBIEmbed } from "powerbi-client-react";
import { PrimaryButton } from "office-ui-fabric-react";
import { handleAxiosError } from "store/util";

type PostalCode = {
  id: string;
  name: string;
};

let postalCodesList: PostalCode[] = [];

export const PolandReportPage = () => {
  const [
    embedMetadata = { reportId: null, accessToken: null, embedUrl: "" },
    setEmbedMetadata,
  ] = useState<PowerBIEmbedMeta>();
  const [report, setReport] = useState<Report>();
  const [years, setYears] = useState([]);
  const [selectedYears, setSelectedYears] = useState(null);
  const [reportStatus, setReportStatus] = useState("loaded");
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedVillage, setSelectedVillage] = useState<PostalCode | null>(
    null
  );

  useEffect(() => {
    let yearsList = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 2007; year--) {
      yearsList.push({ value: year + "", label: year + "" });
    }
    setYears(yearsList);
  }, []);

  useEffect(() => {
    const csv = "/PostalCodes.csv";
    Papa.parse(csv, {
      header: true,
      download: true,
      complete: (response) => {
        postalCodesList = response.data.map((el) => ({
          id: el.ID,
          name: el.Code,
        }));
      },
    });
  }, []);

  const refreshToken = useCallback(() => {
    (async () => {
      let parameters = {
        filter: ``,
      };
      const refreshEmbedObj: any = await getPowerBIEmbedMeta("pl", parameters);
      if (report) {
        report.setAccessToken(refreshEmbedObj.data.accessToken);
      }
      setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
    })();
  }, []);

  const submitCalculation = () => {
    setReportStatus("loading");
    (async () => {
      let parameters = {
        filter: `Postal_x0020_Codes/ID in (${
          selectedVillage.id
        }) and Calendar/Date ge datetime'${
          selectedYears[0].value
        }-01-01T00:00:00' and Calendar/Date lt datetime'${
          parseInt(selectedYears[0].value) + 1
        }-01-01T00:00:00'`,
      };
      try {
        const embedObj: any = await getPowerBIEmbedMeta("pl", parameters);
        setEmbedMetadata(embedObj.data);
      } catch (err) {
        setErrorMessage(handleAxiosError(err));
        handleAxiosError(err);
        setReportStatus("loaded");
      }
    })();
    setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
  };

  const handleOnSelect = (item: PostalCode) => {
    setSelectedVillage(item);
  };

  const powerBiReport = useMemo(() => {
    return (
      <PowerBIEmbed
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                setReportStatus("loaded");
              },
            ],
            [
              "rendered",
              function () {
                setReportStatus("rendered");
              },
            ],
            [
              "error",
              function (event) {
                setReportStatus("error");
              },
            ],
          ])
        }
        embedConfig={{
          type: "report",
          id: embedMetadata.reportId,
          embedUrl: embedMetadata.embedUrl,
          accessToken: embedMetadata.accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          },
        }}
        cssClassName={"analytics-report-class"}
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport as Report);
        }}
      />
    );
  }, [
    embedMetadata.accessToken,
    embedMetadata.embedUrl,
    embedMetadata.reportId,
  ]);
  const filterConfig: Config = {
    ignoreCase: false,
    ignoreAccents: false,
    trim: false,
    matchFrom: "start",
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 2 }}>
        <div
          style={{ display: "flex", flexDirection: "column", padding: "20px" }}
        >
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Postal Code
          </label>
          <ReactSearchAutocomplete<PostalCode>
            items={postalCodesList}
            onSelect={handleOnSelect}
            onClear={() => setSelectedVillage(null)}
            autoFocus
            styling={{
              zIndex: 1000,
              border: "1px solid #ccc",
              borderRadius: "4px",
              height: "36px",
            }}
          />
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Year
          </label>
          <Select
            options={years}
            value={selectedYears}
            onChange={(el) => setSelectedYears([el as any])}
            filterOption={createFilter(filterConfig)}
          />
          <PrimaryButton
            style={{ marginTop: "30px" }}
            text={reportStatus !== "loading" ? "Display Report" : "Loading..."}
            disabled={
              reportStatus === "loading" || !selectedVillage || !selectedYears
            }
            onClick={submitCalculation}
          />
          <span style={{ marginTop: "30px", color: "red" }}>
            {errorMessage}
          </span>
        </div>
      </div>
      <div
        style={{
          flex: 8,
        }}
      >
        {powerBiReport}
      </div>
    </div>
  );
};
