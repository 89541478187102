import { createSlice } from "@reduxjs/toolkit";

export type AuthState = {
  userRole: null | string[];
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userRole: null,
  } as AuthState,
  reducers: {
    setUserRole: (s, a: any) => {
      s.userRole = a.payload;
    },
  },
});

export const {
  actions: { setUserRole },
  reducer: authReducer,
} = authSlice;
