import { GrecoSpinner } from "@greco/components";
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react";
import { FarmerReportPage } from "pages/analytics/FarmerReportPage";
import { HungaryReportPage } from "pages/analytics/HungaryReportPage";
import { InsurerReportPage } from "pages/analytics/InsurerReportPage";
import { PolandReportPage } from "pages/analytics/PolandReportPage";
import React, { Suspense, useState } from "react";
import { useSelector } from "store/hooks";

export const Homepage = () => {
  const userRole: string[] | null = useSelector(
    (s) => (s.auth as any).userRole
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const _items: ICommandBarItemProps[] = [
    {
      key: "tab0",
      text: "Insurer Report",
      ariaLabel: "Insurer Report",
      iconProps: { iconName: "Shield" },
      className: selectedTab === 0 ? "selectedMenuItem" : "",
      onClick: () => {
        setSelectedTab(0);
      },
    },
    {
      key: "tab1",
      text: "Farmer Report",
      ariaLabel: "Download",
      iconProps: { iconName: "Street" },
      className: selectedTab === 1 ? "selectedMenuItem" : "",
      onClick: () => {
        setSelectedTab(1);
      },
    },
    {
      key: "tab2",
      text: "Hungary Report",
      ariaLabel: "Download",
      iconProps: { iconName: "Street" },
      className: selectedTab === 2 ? "selectedMenuItem" : "",
      onClick: () => {
        setSelectedTab(2);
      },
      hidden: !userRole?.includes("SWI-Hu"),
    },
    {
      key: "tab3",
      text: "Poland Report",
      ariaLabel: "Download",
      iconProps: { iconName: "Street" },
      className: selectedTab === 3 ? "selectedMenuItem" : "",
      onClick: () => {
        setSelectedTab(3);
      },
      hidden: true, //!userRole?.includes("SWI-Pl"),
    },
  ];

  return (
    <>
      <Suspense fallback={<GrecoSpinner />}>
        {userRole?.includes("SWI-Insurers") ? <InsurerReportPage /> : null}
        {userRole?.includes("SWI-Admins") ? (
          <>
            <CommandBar items={_items}></CommandBar>
            <div style={{ display: selectedTab === 0 ? "inline" : "none" }}>
              <InsurerReportPage />
            </div>
            <div style={{ display: selectedTab === 1 ? "inline" : "none" }}>
              <FarmerReportPage />
            </div>
            {userRole?.includes("SWI-Hu") ? (
              <div style={{ display: selectedTab === 2 ? "inline" : "none" }}>
                <HungaryReportPage />
              </div>
            ) : null}
            {userRole?.includes("SWI-Pl") ? (
              <div style={{ display: selectedTab === 3 ? "inline" : "none" }}>
                <PolandReportPage />
              </div>
            ) : null}
          </>
        ) : null}
        {userRole?.includes("SWI-Farmers") ? <FarmerReportPage /> : null}
        {userRole?.includes("SWI-Hu") && !userRole?.includes("SWI-Admins") ? (
          <HungaryReportPage />
        ) : null}
        {userRole?.includes("SWI-Pl") && !userRole?.includes("SWI-Admins") ? (
          <PolandReportPage />
        ) : null}
      </Suspense>
    </>
  );
};
