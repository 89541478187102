import { PrimaryButton } from "office-ui-fabric-react";
import Papa from "papaparse";
import { models, Report } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select, { createFilter } from "react-select";
import { Config } from "react-select/src/filters";
import { handleAxiosError } from "store/util";
import { getPowerBIEmbedMeta } from "../../store/api/api";
import { PowerBIEmbedMeta } from "../../types/types";

let countiesList = [];
let villagesList = [];

export const HungaryReportPage = () => {
  const [
    embedMetadata = { reportId: null, accessToken: null, embedUrl: "" },
    setEmbedMetadata,
  ] = useState<PowerBIEmbedMeta>();
  const [report, setReport] = useState<Report>();

  const [villages, setVillages] = useState([]);
  const [counties, setCounties] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState(null);
  const [selectedYears, setSelectedYears] = useState(null);
  const [reportStatus, setReportStatus] = useState("loaded");
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);

  useEffect(() => {
    let yearsList = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 2007; year--) {
      yearsList.push({ value: year + "", label: year + "" });
    }
    setYears(yearsList);
  }, []);

  useEffect(() => {
    const csv = "/Villages.csv";
    Papa.parse(csv, {
      header: true,
      download: true,
      complete: (response) => {
        villagesList = response.data;
        villagesList
          .filter((el) => !!el.Village)
          .forEach((el) => {
            const fullVillageName: string = el.Village;
            const county = fullVillageName.substring(
              fullVillageName.indexOf("(") + 1,
              fullVillageName.indexOf(")")
            );
            if (!countiesList.includes(county)) {
              countiesList.push(county);
            }
          });
        setCounties(
          countiesList
            .map((el) => ({ value: el, label: el }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
      },
    });
  }, []);

  const refreshToken = useCallback(() => {
    (async () => {
      let parameters = {
        filter: ``,
      };
      const refreshEmbedObj: any = await getPowerBIEmbedMeta("hu", parameters);
      if (report) {
        report.setAccessToken(refreshEmbedObj.data.accessToken);
      }
      setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
    })();
  }, []);

  const changeVillage = (selection) => {
    setSelectedVillage(
      villagesList.find((el) => el.VillageID === selection.value)
    );
  };

  const submitCalculation = () => {
    setReportStatus("loading");
    (async () => {
      let parameters = {
        filter: `Villages/VillageID in (${
          selectedVillage.VillageID
        }) and Calendar/Date ge datetime'${
          selectedYears[0].value
        }-01-01T00:00:00' and Calendar/Date lt datetime'${
          parseInt(selectedYears[0].value) + 1
        }-01-01T00:00:00'`,
      };
      try {
        const embedObj: any = await getPowerBIEmbedMeta("hu", parameters);
        setEmbedMetadata(embedObj.data);
      } catch (err) {
        setErrorMessage(handleAxiosError(err));
        handleAxiosError(err);
        setReportStatus("loaded");
      }
    })();
    setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
  };

  const changeCounty = (selection) => {
    setSelectedCounty(countiesList.find((el) => el === selection.value));
    setSelectedVillage(null);
  };

  useEffect(() => {
    if (!selectedCounty) {
      return;
    }
    let items = villagesList.filter((el) => {
      if (!el.Village) {
        return false;
      }

      const fullVillageName: string = el.Village;
      const county = fullVillageName.substring(
        fullVillageName.indexOf("(") + 1,
        fullVillageName.indexOf(")")
      );
      return county === selectedCounty;
    });

    items = items.map((el2) => {
      const fullVillageName: string = el2.Village;
      const county = fullVillageName.substring(
        fullVillageName.indexOf("(") + 1,
        fullVillageName.indexOf(")")
      );
      if (!countiesList.includes(county)) {
        countiesList.push(county);
      }
      const village = fullVillageName.substring(
        0,
        fullVillageName.indexOf("(") - 1
      );
      return {
        value: el2.VillageID,
        label: village,
      };
    });
    setVillages(items.sort((a, b) => a.label.localeCompare(b.label)));
  }, [selectedCounty]);

  const powerBiReport = useMemo(() => {
    return (
      <PowerBIEmbed
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                setReportStatus("loaded");
              },
            ],
            [
              "rendered",
              function () {
                setReportStatus("rendered");
              },
            ],
            [
              "error",
              function (event) {
                setReportStatus("error");
              },
            ],
          ])
        }
        embedConfig={{
          type: "report",
          id: embedMetadata.reportId,
          embedUrl: embedMetadata.embedUrl,
          accessToken: embedMetadata.accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          },
        }}
        cssClassName={"analytics-report-class"}
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport as Report);
        }}
      />
    );
  }, [
    embedMetadata.accessToken,
    embedMetadata.embedUrl,
    embedMetadata.reportId,
  ]);
  const filterConfig: Config = {
    ignoreCase: false,
    ignoreAccents: false,
    trim: false,
    matchFrom: "start",
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 2 }}>
        <div
          style={{ display: "flex", flexDirection: "column", padding: "20px" }}
        >
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            County
          </label>
          <Select options={counties} onChange={changeCounty} />
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Village
          </label>
          <Select
            options={villages}
            onChange={changeVillage}
            isDisabled={selectedCounty === null}
          />
          <label
            style={{
              width: "100%",
              flex: 1,
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Year
          </label>
          <Select
            options={years}
            value={selectedYears}
            onChange={(el) => setSelectedYears([el as any])}
            isDisabled={selectedVillage === null}
            filterOption={createFilter(filterConfig)}
          />
          <PrimaryButton
            style={{ marginTop: "30px" }}
            text={reportStatus !== "loading" ? "Display Report" : "Loading..."}
            disabled={
              reportStatus === "loading" || !selectedVillage || !selectedYears
            }
            onClick={submitCalculation}
          />
          <span style={{ marginTop: "30px", color: "red" }}>
            {errorMessage}
          </span>
        </div>
      </div>
      <div
        style={{
          flex: 8,
        }}
      >
        {powerBiReport}
      </div>
    </div>
  );
};
